export const menuItems = [
  {
    name: "demos",
    label: "Treatments",
    items: [
      { name: "non-surgical-nose-job", label: "Non Surgical Nose Job" },
      {
        name: "lip-filler",
        label: "Lip Filler",
      },

      {label: "Hair",
      items: [
        {name: "hair-restoration-london", label: "PRP Hair Loss"},
        {name: "exosomes-hair-loss", label: "Exosomes Hair Loss"},
        {name: "exomeso", label: "ExoMeso"},
        {name: "mesotherapy-hair", label: "Mesotherapy Hair"},
     
    ],},


    {label: "Exosomes",
    items: [
      {name: "exosomes-hair-loss", label: "Exosomes Hair Loss"},
    
   
   
  ],},
    
     
   
      {label: "Dermal Filler",
      items: [
     
 
      {name: "filler-packages", label: "Filler Packages"},
      {name: "tear-trough-under-eye", label: "Tear Trough"},
      {name: "non-surgical-nose-job", label: "Nose Filler"},
      {name: "temple-filler-uk", label: "Temple Filler"},
      {name: "lip-filler", label: "Lip Filler"},
      {name: "chin-filler", label: "Chin Filler"},
      {name: "jaw-filler", label: "Jaw Filler"},
      {name: "cheek-augmentation", label: "Cheek Augmentation"},
      {name: "smile-line", label: "Smile Lines"},
      {name: "profhilo", label: "Profhilo Skin Booster"},
     
     
    ],},


    {label: "Anti Ageing",
    items: [
    {name: "anti-wrinkle-treatments", label: "Anti-Wrinkle"},
    {name: "corner-of-mouth", label: "Corner of Mouth"},
    {name: "eyebrow-lift", label: "Eyebrow Lift"},
    {name: "masseter", label: "Masseter"},
    {name: "gummy-smile", label: "Gummy Smile"},
   
   
  ],},
    
  {label: "Skin",
    items: [
      {name: "skinpen-microneedling", label: "Microneedling"},
      {name: "mesotherapy", label: "Mesotherapy"},
      {name: "prp-face-lift", label: "Face Lift"},
    
    {name: "acne-peel", label: "Acne Peel"},
   
  
    {name: "advanced-facial-treatments-london", label: "Advanced Facials"},

   
    {name: "hydrafacial", label: "HydraFacial"},
    

    ],},
 
  {
    name: "polynucleotides",
    label: "Polynucleotides",
  },

  
  
  {label: "Skin Booster",
  items: [
    {name: "profhilo", label: "Profhilo"},
    {name: "profhilo-structura", label: "Profhilo Structura"},
    {name: "seventy-hyal-2000", label: "Seventy Hyal 2000"},
    {name: "polynucleotides", label: "Polynucleotides"},

  ],},

    
     


      {label: "Body",
      items: [
        {name: "laser-hair-removal", label: "Laser Hair Removal"},
        {name: "fat-dissolving", label: "Fat Dissolving"},
     
     
    ],},

    
      

    ], 
  },

  {
    name: "conditions-page",
    label: "Conditions",
  },

  {
    name: "price-list",
    label: "Price List",
  },
  {
    name: "faq",
    label: "FAQ",
  },
  {
    name: "dermamina-blog",
    label: "Blog",
  },
  {label: "Contact",
  items: [
  {name: "contact", label: "Contact Us"},
  {name: "about", label: "About Us"},
  {name: "become-a-model", label: "Become A Model"},
  {name: "cosmetics-influencer", label: "Calling All Influencers"},
  ],
},


];
